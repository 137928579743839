// @flow
import React from 'react';
import {useState} from "react";
import { withPrefix, Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import {Auth} from "aws-amplify";
import { useSiteMetadata } from '../../hooks';
import {Row, Col, Container, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter,  } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import type { Node } from '../../types';

type Props = {
  post: Node
};
const flagError = "<p>Wrong flag ! </p>"

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, locked, boxName, OS } = post.frontmatter;
  const {author} = useSiteMetadata();
  const [authState, setAuthState] = useState('')
  const [modal,setModal] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [errorValue, setErrorValue] = useState('')
  //console.log("Data : ", boxName, "/", title)

  const hintPassFormat = (OS) => {
    let message;
        if (OS === "linux") {
            message = ["Linux format : $6$","Haot[...]BEKv./",":18481:0:99999:7:::"]
        }
        else {
            message = ["Win format : ", "Admin", "NTLM", "hash"]
        }

        return message
      }
  const handleAuthStateChange = (state) => {
      if (state === "signedin") {
          setAuthState(state)
      }
  }
  const handleErrorValue = (state) => {
    let message = state["message"];  
    if (message.includes("username")){
        message = message.replace("username or ", "")
      }
    if (message.includes("exceeded")){
        message = message.replace("Password attempts exceeded", "Not gonna work, mate !")
    }
    setErrorValue(message)
  }

  async function unlockPost(box, password){
      let pw = password
      //console.log("Trying to log in with creds : ", box, " / ", password)
      try {
        const login = await Auth.signIn(box, pw);
        handleAuthStateChange("signedin")
        
    } catch (error) {
        console.log('error signing in', error);
        handleErrorValue(error)
    }
    
  }

  const handleInput = (input) => {
    setInputValue(input)
  }


  if (authState === "signedin" || !locked) {
      return(
        <div>
            <div className={styles['post']}>
              <div className={styles["post__backthumbCol"]}>      
                <Link to="/">
                  <i className={styles['post__arrow']}/>
                  <img
                    src={withPrefix(author.photo)}
                    className={styles['post__authorPhoto']}
                    width="45"
                    height="45"
                    alt={author.name}
                  />
                </Link>
              </div>
              <div className={styles['post__content']}>
                  <Content body={html} title={title} />
              </div>
              


              <div className={styles['post__footer']}>
                  <Meta date={date} />
                  {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
                  <Author />
              </div>

              <div className={styles['post__comments']}>
                  <Comments postSlug={slug} postTitle={post.frontmatter.title} />
              </div>
            </div>
        </div>
      );
  }
  else {
    let passFormat = hintPassFormat(post.frontmatter.OS)
  return (
    <div>
      <Row>
        <Col className={styles["post__backthumbCol"]}>      
          <Link to="/">
            <i className={styles['post__arrow']}/>
            <img
              src={withPrefix(author.photo)}
              className={styles['post__authorPhoto']}
              width="45"
              height="45"
              alt={author.name}
            />
          </Link>
        
        </Col>
        </Row>
        <Row>
        <Col className={styles["post__thumbnailCol"]}>
            <img 
              className={styles['post__thumbnail']} 
              src={"../"+post.frontmatter.thumbnail}
            />  
        </Col>
      </Row>

      <Row>
        <Col className={styles["post__protectedTitleCol"]}>
          {boxName} write-up is protected !<br />
        </Col>
      </Row>
      <Row className={styles["post__formRow"]}>

        <Col>
              <Form>
                  <FormGroup className={styles["post__passwordForm"]}>
                  <Input 
                      className={styles["post__passwordInput"]}
                      value={inputValue}
                      onChange={event => handleInput(event.target.value)} 
                      type="password" 
                      name="password" 
                      id="postPassword" 
                      placeholder="Root password hash" />
                  <div className={styles["post__hintPassword"]}>
                    {passFormat[0]}<b><u>{passFormat[1]}</u></b>{passFormat[2]}  
                  </div>
                  <div className={styles["post__div_passwordSubmit"]}>
                  <Button className={styles["post__passwordSubmit"]} onClick={()=>unlockPost(post.frontmatter.boxName.toLowerCase(), inputValue )}>Submit</Button>
                  </div>
                  </FormGroup>

                  {errorValue != '' ? 
                      <div className={styles["post__passwordError"]}>{errorValue}</div> 
                      :
                      null}
                  
              </Form>
              </Col>
      </Row>


          



    </div>
  );
}
};

export default Post;
